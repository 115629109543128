/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import {Grid} from '@material-ui/core'
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import "./becometrainer.css";
import EventsOverview from "../components/EventsOverview";
import SEO from "../components/SEO";
import Contact from "../components/Contact";
import MultiImageSlider from "../components/MultiImageSlider";
import Banner from "../components/Banner";
import Slider from "react-slick";
import Empfehlungen from "../components/empfehlungen";

const sliderSettings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    fade: true
};

export default class Becometrainerrealqua extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Layout id="becomeTrainerRelaqua">
            <SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
            />
            <Slider {...sliderSettings}>
                {
                    this.props.data.wordpressPage.acf.slider_page.map((slide) => {
                        return (
                            slide.image && <Banner
                                customStyles={{marginTop: 0}}
                                title={slide.title}
                                description={slide.description}
                                image={slide.image}
                            />
                        );
                    })
                }
            </Slider>

            <Grid container type="flex" justify="center" className='content-wrapper'>
                <Grid item xl={6} lg={8} md={12} xs={12} className='content-centered'>
                    <h2 className='title-big'>{this.props.data.wordpressPage.acf.become_trainer.title}</h2>
                    <div>{this.props.data.wordpressPage.acf.become_trainer.description}</div>
                </Grid>
            </Grid>


            <Contact title={this.props.data.wordpressPage.acf.contact.title}
                     image={this.props.data.wordpressPage.acf.contact.image}
                     description={this.props.data.wordpressPage.acf.contact.description} />

            {this.props.data.wordpressPage.acf.video && this.props.data.wordpressPage.acf.video.file &&
            <div className="video-wrapper" style={{margin: '0 auto'}}>
                <video controls className="video" poster={this.props.data.wordpressPage.acf.video.thumbnail && this.props.data.wordpressPage.acf.video.thumbnail.src}>
                    <source src={this.props.data.wordpressPage.acf.video.file.source_url} type="video/mp4" />
                </video>
            </div>
            }

            {/*<div className='dark-hero-content'>
                <div className='title-big'>Preise & Möglichkeiten</div>
            </div>

            <Grid container className='becometrainer-content-row' type="flex" justify="start">
                <Grid item lg={12} md={12} xs={12} className='becometrainer-content-column'>
                    <div className='title-big'>{this.props.data.wordpressPage.acf.preise_und_moeglichkeiten.title}</div>
                    <div>{this.props.data.wordpressPage.acf.preise_und_moeglichkeiten.description}</div>
                </Grid>
                <Grid item lg={4} md={6} xs={12} className='becometrainer-content-column'>
                    <Card className="card">
                        <CardContent style={{padding: '2em'}}>
                            <div className='title-big'>
                                {this.props.data.wordpressPage.acf.preise_und_moeglichkeiten.title_basic}
                            </div>
                            {this.props.data.wordpressPage.acf.preise_und_moeglichkeiten.description_basic}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={4} md={6} xs={12} className='becometrainer-content-column'>
                    <Card className="card">
                        <CardContent style={{padding: '2em'}}>
                            <div className='title-big'>
                                {this.props.data.wordpressPage.acf.preise_und_moeglichkeiten.title_extended}
                            </div>
                            {this.props.data.wordpressPage.acf.preise_und_moeglichkeiten.description_extended}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>*/}

            <div className='dark-hero-content'>
                <h2 className='title-big'>Relaqua Events</h2>
            </div>

            <div className="content">
                <EventsOverview categories={['Relaqua']} />
            </div>

            <MultiImageSlider nodes={this.props.data.wordpressPage.acf.gallery}/>

            <Empfehlungen empfehlungen={this.props.data.wordpressPage.acf.cards_page} />
        </Layout>
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                video {
                    file {
                        source_url
                    }
                    thumbnail {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                contact {
                    title
                    description
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                gallery {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                slider_page {
                    title
                    description
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                become_trainer {
                    title
                    description
                    courses_title
                }
                preise_und_moeglichkeiten {
                    title
                    description
                    title_basic
                    description_basic
                    title_extended
                    description_extended
                }
                cards_page {
                    title
                    description
                    link
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpEvent {
            edges {
                node {
                    id
                    slug
                    acf {
                        event {
                            category
                            shortdescription
                            longdescription
                            enddate
                            location {
                                location
                                street
                                postleitzahl
                            }
                            price
                            title
                            startdate
                            type
                            image {
                                alt_text
                                title
                                description
                                localFile {
                                    childImageSharp {
                                        id
                                        fluid {
                                            base64
                                            tracedSVG
                                            aspectRatio
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                            originalName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


